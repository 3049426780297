import youtubeChannel from './we-have-more/images/play-circle.svg';
import youtubeChannelMobile from './we-have-more/images/mobile-play-circle.svg';
import deepDive from './we-have-more/images/deep-dive.svg';
import deepDiveMobile from './we-have-more/images/mobile-deep-dive.svg';
import knowUsBetter from './we-have-more/images/people-logo.svg';
import knowUsBetterMobile from './we-have-more/images/mobile-people-logo.svg';
import featuredImage from '@images/resources/featured-image-resources-lp.jpg';

import { paths } from '@src/constants/pathsEnum';

export const seoProps = {
  title: "CodiLime's Knowledge Resources: Ebooks, Webinars, Podcasts",
  description:
    'Discover our ebooks, webinars, podcasts and other resources created by world-class software and network engineers. Dive deep into our world!',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const exploreUxShowcase = {
  title: 'Discover our Product Design services',
  buttonLabel: 'Find out more',
};

export const weHaveMoreData = [
  {
    color: 'turquoise',
    titlePartOne: 'See our knowledge-packed',
    titlePartTwo: 'Youtube channel',
    url: 'https://www.youtube.com/c/codilime_com',
    imgSrc: youtubeChannel,
  },
  {
    color: 'dusk',
    titlePartOne: 'Deep dive into our blog',
    titlePartTwo: 'for professionals',
    url: 'https://codilime.com/blog/deep-dive/',
    imgSrc: deepDive,
  },
  {
    color: 'lime',
    titlePartOne: 'Get to know us better',
    url: paths.ABOUT,
    imgSrc: knowUsBetter,
  },
];

export const weHaveMoreDataMobile = [
  {
    color: 'turquoise',
    titlePartOne: 'See our knowledge-packed',
    titlePartTwo: 'Youtube channel',
    url: 'https://www.youtube.com/c/codilime_com',
    imgSrc: youtubeChannelMobile,
  },
  {
    color: 'dusk',
    titlePartOne: 'Deep dive into our blog',
    titlePartTwo: 'for professionals',
    url: 'https://codilime.com/blog/deep-dive/',
    imgSrc: deepDiveMobile,
  },
  {
    color: 'lime',
    titlePartOne: 'Get to know us better',
    url: paths.ABOUT,
    imgSrc: knowUsBetterMobile,
  },
];
