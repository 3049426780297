import React from 'react';

import SEO from '@commons/SEO';
import Layout from '@src/layouts';

import { ExploreUxShowcase, WeHaveMore, OurResources } from '@pages-impl/resources';

import { seoProps } from '@pages-impl/resources/payload';

export default function Resources(props) {
  return (
    <Layout displayTopBanner={false} {...props}>
      <SEO {...seoProps} />
      <OurResources />
      <ExploreUxShowcase />
      <WeHaveMore />
    </Layout>
  );
}
