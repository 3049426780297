import React from 'react';
import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';

import { WeHaveMoreCardDesktop, WeHaveMoreCardMobile } from './index';

import { weHaveMoreData, weHaveMoreDataMobile } from '../payload';

import * as styles from './we-have-more.module.scss';

export const WeHaveMore = () => {
  return (
    <NewStandardSection
      title="We have more"
      id="we-have-more"
      fullWidthOnMobile
      classNames={{ innerWrapper: styles.innerWrapper }}
    >
      <div className={styles.desktopContent}>
        {weHaveMoreData.map((resource) => (
          <WeHaveMoreCardDesktop {...resource} key={`desktop-${resource.titlePartOne}`} />
        ))}
      </div>
      <div className={styles.mobileContent}>
        {weHaveMoreDataMobile.map((resource) => (
          <WeHaveMoreCardMobile {...resource} key={`mobile-${resource.titlePartOne}`} />
        ))}
      </div>
    </NewStandardSection>
  );
};
