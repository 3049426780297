import React from 'react';
import { FilterButton } from '../filter-button/FilterButton';

import * as styles from './panel-with-tags.module.scss';

const PanelWithTags = ({ containerName, allTagsList, activeTags, toggleTagActivity }) => {
  return (
    <div className={styles.mainContainer}>
      <h3 className={styles.tagsTitle}>{containerName}</h3>
      {allTagsList.map((tag) => {
        return <FilterButton tag={tag} key={tag} activeTags={activeTags} onClickHandler={toggleTagActivity} />;
      })}
    </div>
  );
};

export default React.memo(PanelWithTags);
