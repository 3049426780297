import React from 'react';

import * as styles from './filter-button.module.scss';

export const FilterButton = ({ tag, activeTags, onClickHandler }) => {
  const isActive = activeTags.includes(tag);
  return (
    <button
      key={tag}
      name={tag}
      className={`${styles.containerForType} ${isActive ? styles.activeTag : styles.inactiveTag}`}
      onClick={onClickHandler}
    >
      {tag}
      {isActive && <span className={styles.iconContainer}></span>}
    </button>
  );
};
