import React from 'react';

import cx from 'classnames';

import * as styles from './we-have-more-card-mobile.module.scss';
import { Link } from '@commons/link/Link';

export const WeHaveMoreCardMobile = ({ titlePartOne, titlePartTwo, imgSrc, color, url }) => {
  return (
    <div
      className={cx(styles.mobileCard, {
        [styles.turquoiseCard]: color === 'turquoise',
        [styles.duskBackground]: color === 'dusk',
        [styles.limeBackground]: color === 'lime',
      })}
    >
      <Link href={url} className={styles.link} />
      <div className={styles.titleContainer}>
        <span>{titlePartOne}</span>
        {titlePartTwo && <span>{titlePartTwo}</span>}
      </div>
      <div className={styles.imageContainer}>
        <img loading="lazy" src={imgSrc} alt={titlePartOne} />
      </div>
    </div>
  );
};
