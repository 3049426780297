// extracted by mini-css-extract-plugin
export var cardContainer = "resource-card-module--card-container--692fa";
export var cardLink = "resource-card-module--card-link--9cbdf";
export var cardTitle = "resource-card-module--card-title--3e922";
export var horizontalBreak = "resource-card-module--horizontal-break--f255a";
export var label = "resource-card-module--label--7d71a";
export var labelDemo = "resource-card-module--label-demo--3f1e9";
export var labelEbook = "resource-card-module--label-ebook--67419";
export var labelOpenSources = "resource-card-module--label-open-sources--7fb62";
export var labelPodcast = "resource-card-module--label-podcast--0c13b";
export var labelVideo = "resource-card-module--label-video--8b2b5";
export var labelWebinar = "resource-card-module--label-webinar--c5da5";
export var mainImage = "resource-card-module--main-image--89428";
export var tags = "resource-card-module--tags--6e3fa";