import React from 'react';

import * as styles from './filter-button-on-mobile.module.scss';

export const FilterButtonOnMobile = ({ tag, activeTags, onClickHandler }) => {
  const isActive = activeTags.includes(tag);
  return (
    <button
      className={`${styles.containerForType} ${isActive ? styles.activeTag : styles.inactiveTag}`}
      onClick={onClickHandler}
      name={tag}
    >
      <span className={styles.containerForCheckbox}></span>
      {tag}
    </button>
  );
};
