import { resourcesData } from './payload';

const listOfSortedTags = (resourcesData, keyName) => {
  const tags = new Set();
  resourcesData.forEach((resourceData) => {
    if (Array.isArray(resourceData[keyName])) {
      resourceData[keyName].forEach((item) => tags.add(item));
    } else {
      tags.add(resourceData[keyName]);
    }
  });

  return [...tags].sort();
};

export const contentTypesList = listOfSortedTags(resourcesData, 'label');
export const topicsList = listOfSortedTags(resourcesData, 'topic');

const getAllTagsAndTitles = () => {
  const tagsAndTitles = new Set();
  resourcesData.map((resourceData) => {
    resourceData['tags'].forEach((tag) => tagsAndTitles.add(tag));
    tagsAndTitles.add(resourceData['cardTitle'].replace('[PL] ', ''));
  });
  return tagsAndTitles;
};

export const sortedListOptionsForInput = [...getAllTagsAndTitles(), ...contentTypesList, ...topicsList].sort(
  Intl.Collator().compare,
);
