import React, { useState } from 'react';
import cx from 'classnames';

import { Button } from '@commons/button/Button';

import { FilterButtonOnMobile } from '../filter-button-on-mobile/FilterButtonOnMobile';

import * as styles from './menu-on-mobile.module.scss';

const MenuOnMobile = ({ data }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <div className={styles.filterContainer}>
      <Button label="Filters" onClick={handleToggle} />
      <div className={cx(styles.menuToFilter, !menuOpen ? styles.hideMenu : styles.showMenu)}>
        <div className={styles.containerForTypes}>
          {data.map((tagData) => (
            <div className={styles.mainContainer} key={tagData.containerName}>
              <h3 className={styles.tagsTitle}>{tagData.containerName}</h3>
              {tagData.allTagsList.map((tag) => {
                return (
                  <FilterButtonOnMobile
                    tag={tag}
                    key={tag}
                    activeTags={tagData.activeTags}
                    onClickHandler={tagData.toggleTagActivity}
                  />
                );
              })}
            </div>
          ))}
        </div>
        <Button label="Close filters" className={styles.closeFiltersButton} onClick={() => setMenuOpen(false)} />
      </div>
    </div>
  );
};

export default React.memo(MenuOnMobile);
