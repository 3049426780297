import React from 'react';

import cx from 'classnames';

import * as styles from './resource-card.module.scss';
import { Link } from '@commons/link/Link';

export const ResourceCard = ({ label, url, imgSrc, imgAlt, cardTitle, tags }) => {
  const addLinebreak = (string) =>
    string.split('(').map((item, index) => (index === 0 ? item : [<br key={index} />, '(' + item]));

  return (
    <div className={styles.cardContainer}>
      <Link className={styles.cardLink} href={url} />
      <img loading="lazy" src={imgSrc} alt={imgAlt} className={styles.mainImage} />
      <div
        className={cx(styles.label, {
          [styles.labelEbook]: label === 'Ebook',
          [styles.labelWebinar]: label === 'Webinar',
          [styles.labelVideo]: label === 'Video',
          [styles.labelPodcast]: label === 'Podcast',
          [styles.labelOpenSources]: label === 'Open source',
          [styles.labelDemo]: label === 'Demo',
        })}
      >
        {label}
      </div>
      <h4 className={styles.cardTitle}>{addLinebreak(cardTitle)}</h4>
      <hr className={styles.horizontalBreak} />
      <span className={styles.tags}>Tags: {tags.join(', ')}</span>
    </div>
  );
};
