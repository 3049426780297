import React from 'react';

import cx from 'classnames';

import * as styles from './we-have-more-card.module.scss';
import { Link } from '@commons/link/Link';

export const WeHaveMoreCardDesktop = ({ color, titlePartOne, titlePartTwo, url, imgSrc }) => {
  return (
    <div className={styles.cardsContainer}>
      <div
        className={cx(styles.staticCard, {
          [styles.turquoiseCard]: color === 'turquoise',
          [styles.duskBackground]: color === 'dusk',
          [styles.limeBackground]: color === 'lime',
        })}
      >
        <span>{titlePartOne}</span>
        <br />
        {titlePartTwo && <span>{titlePartTwo}</span>}
      </div>
      <div className={styles.movingCard}>
        <div
          className={cx(styles.imageContainer, {
            [styles.turquoiseCard]: color === 'turquoise',
            [styles.duskBackground]: color === 'dusk',
            [styles.limeBackground]: color === 'lime',
          })}
        >
          <img loading="lazy" className={styles.image} src={imgSrc} alt="Play" />
        </div>
        <Link className={styles.cardLink} href={url} />
        <span className={styles.cardTitle}>{titlePartOne}</span>
        <br />
        {titlePartTwo && <span className={styles.cardTitle}>{titlePartTwo}</span>}
      </div>
    </div>
  );
};
