// extracted by mini-css-extract-plugin
export var cardLink = "we-have-more-card-module--card-link--ea046";
export var cardTitle = "we-have-more-card-module--card-title--3b70a";
export var cardsContainer = "we-have-more-card-module--cards-container--e69e5";
export var duskBackground = "we-have-more-card-module--dusk-background--b70b9";
export var image = "we-have-more-card-module--image--afcf4";
export var imageContainer = "we-have-more-card-module--image-container--02051";
export var limeBackground = "we-have-more-card-module--lime-background--ad41c";
export var movingCard = "we-have-more-card-module--moving-card--23596";
export var staticCard = "we-have-more-card-module--static-card--4d797";
export var turquoiseCard = "we-have-more-card-module--turquoise-card--dffe6";