import React, { useState, useEffect, useRef } from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { Button, ButtonTypeEnum } from '@commons/button';
import { ResourceCard } from '@pages-impl/resources';
import PanelWithTags from './panel-with-tags/PanelWithTags';
import { InputToFilter } from '@src/components/commons';

import { resourcesData, sectionsData } from './payload';
import { contentTypesList, topicsList, sortedListOptionsForInput } from './our-resources-helper-functions';

import * as styles from './our-resources.module.scss';
import MenuOnMobile from './menu-on-mobile/MenuOnMobile';

export const OurResources = () => {
  const [windowWidth, detectWindowWidth] = useState(1200);
  const [displayedResources, setDisplayedResources] = useState(resourcesData);
  const [inputValue, setInputValue] = useState('');
  const [displayedResourcesNumber, setDisplayedResourcesNumber] = useState(3);
  const [activeTypes, setActiveTypes] = useState([]);
  const [activeTopics, setActiveTopics] = useState([]);
  const changeType = useRef(true);
  const scrollToTop = useRef(null);
  const ifScrollToTopResources = useRef(false);

  const { standardSectionData, inputToFilterData } = sectionsData;

  const resetInput = () => {
    setInputValue('');
    setDisplayedResources(resourcesData);
  };

  const handleClick = () => {
    scrollToTop.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const changeDisplayedResourcesNumber = () => {
    if (windowWidth > 1080) {
      displayedResourcesNumber !== 9 && setDisplayedResourcesNumber(9);
    } else {
      displayedResourcesNumber !== 3 && setDisplayedResourcesNumber(3);
    }
  };

  const detectSize = () => {
    detectWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      changeDisplayedResourcesNumber();
      window.removeEventListener('resize', detectSize);
    };
  }, [windowWidth]);

  useEffect(() => {
    if (changeType.current === true) {
      if (activeTypes.length === 0 && activeTopics.length === 0) {
        setDisplayedResources(resourcesData);
      } else {
        setDisplayedResources(
          resourcesData.filter((resource) => {
            if (Array.isArray(resource['topic'])) {
              return (
                activeTypes.includes(resource['label']) || resource['topic'].some((item) => activeTopics.includes(item))
              );
            }
            return activeTypes.includes(resource['label']) || activeTopics.includes(resource['topic']);
          }),
        );
        setInputValue('');
      }
    }
  }, [activeTypes, activeTopics]);

  useEffect(() => {
    changeDisplayedResourcesNumber();
    if (ifScrollToTopResources.current === true) {
      handleClick();
    }
  }, [activeTypes, activeTopics, inputValue]);

  const toggleTypeActivity = (event) => {
    const labelName = event.currentTarget.getAttribute('name');
    changeType.current = true;
    if (activeTypes.includes(labelName)) {
      setActiveTypes(activeTypes.filter((data) => data !== labelName));
    } else {
      setActiveTypes((prevState) => [...prevState, labelName]);
    }
    ifScrollToTopResources.current = true;
  };

  const toggleTopicActivity = (event) => {
    const topicName = event.currentTarget.getAttribute('name');
    changeType.current = true;
    if (activeTopics.includes(topicName)) {
      setActiveTopics(activeTopics.filter((data) => data !== topicName));
    } else {
      setActiveTopics((prevState) => [...prevState, topicName]);
    }
    ifScrollToTopResources.current = true;
  };

  const changeInputValue = (event) => {
    setInputValue(event.target.value);
    const inputValue = event.target.value.toLowerCase();
    if (inputValue === '') {
      setDisplayedResources(resourcesData);
    } else {
      setDisplayedResources(
        resourcesData.filter(
          (resource) =>
            resource['cardTitle'].toLowerCase().includes(inputValue) ||
            resource['label'].toLowerCase().includes(inputValue) ||
            (typeof resource['topic'] === 'string'
              ? resource['topic'].toLowerCase().includes(inputValue)
              : resource['topic'].some((topicItem) => topicItem.toLowerCase().includes(inputValue))) ||
            resource['tags'].some((tag) => tag.toLowerCase().includes(inputValue)),
        ),
      );
      changeType.current = false;
      setActiveTypes([]);
      setActiveTopics([]);
    }
  };

  const showNextResources = () => {
    windowWidth > 1080
      ? setDisplayedResourcesNumber(Math.min(displayedResourcesNumber + 6, displayedResources.length))
      : setDisplayedResourcesNumber(Math.min(displayedResourcesNumber + 3, displayedResources.length));
  };
  return (
    <NewStandardSection
      {...standardSectionData}
      classNames={{
        titlesContainer: styles.titlesContainer,
        titleStyles: styles.mainTitle,
        subtitleStyles: styles.subtitle,
        innerWrapper: styles.innerWrapper,
      }}
      id="our-resources"
    >
      <InputToFilter
        {...inputToFilterData}
        value={inputValue}
        onChangeHandler={changeInputValue}
        sortedListOptionsForInput={sortedListOptionsForInput}
        customStyles={styles.textInput}
        onClickCrossHandler={resetInput}
      />
      <div className={styles.content} ref={scrollToTop}>
        <div className={styles.sideMenu}>
          <PanelWithTags
            containerName="Content type"
            allTagsList={contentTypesList}
            activeTags={activeTypes}
            toggleTagActivity={toggleTypeActivity}
          />
          <PanelWithTags
            containerName="Topic"
            allTagsList={topicsList}
            activeTags={activeTopics}
            toggleTagActivity={toggleTopicActivity}
          />
        </div>
        <div className={styles.menuOnMobile}>
          <MenuOnMobile
            data={[
              {
                containerName: 'Content type',
                allTagsList: contentTypesList,
                activeTags: activeTypes,
                toggleTagActivity: toggleTypeActivity,
              },
              {
                containerName: 'Topic',
                allTagsList: topicsList,
                activeTags: activeTopics,
                toggleTagActivity: toggleTopicActivity,
              },
            ]}
          />
        </div>
        <div className={styles.containerWithTiles}>
          <div className={styles.allTitles}>
            {displayedResources.slice(0, displayedResourcesNumber).map((resourceData, index) => (
              <ResourceCard
                {...resourceData}
                imgAlt={`${resourceData.cardTitle}/resource`}
                key={resourceData['cardTitle'] + index}
              />
            ))}
          </div>
          {displayedResourcesNumber < displayedResources.length && (
            <Button onClick={showNextResources} type={ButtonTypeEnum.BLUE} label="Load more" arrowType="right" />
          )}
        </div>
      </div>
    </NewStandardSection>
  );
};
