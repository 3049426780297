// extracted by mini-css-extract-plugin
export var allTitles = "our-resources-module--all-titles--b4a84";
export var buttonToLoad = "our-resources-module--button-to-load--e3714";
export var containerWithTiles = "our-resources-module--container-with-tiles--2e2a3";
export var content = "our-resources-module--content--4f2ef";
export var innerWrapper = "our-resources-module--inner-wrapper--3cd9c";
export var mainTitle = "our-resources-module--main-title--2b401";
export var menuOnMobile = "our-resources-module--menu-on-mobile--12536";
export var sideMenu = "our-resources-module--side-menu--6f10b";
export var subtitle = "our-resources-module--subtitle--e68a4";
export var textInput = "our-resources-module--text-input--aea82";
export var titlesContainer = "our-resources-module--titles-container--0c462";