import React from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';

import { Button } from '@commons/button';

import { paths } from '@src/constants/pathsEnum';
import { exploreUxShowcase } from '../payload';

import laptop from './images/laptop.png';

import * as styles from './explore-ux-showcase.module.scss';

export const ExploreUxShowcase = () => {
  const { title, buttonLabel } = exploreUxShowcase;
  return (
    <NewStandardSection
      id="ux-showcase"
      fullWidthOnMobile
      classNames={{ innerWrapper: styles.innerWrapper, outerWrapper: styles.outerWrapper }}
    >
      <div className={styles.mainBanner}>
        <div className={styles.imageContainer}>
          <img loading="lazy" src={laptop} alt={title} className={styles.image} />
          <span className={styles.titleOnMobile}>{title}</span>
        </div>
        <div className={styles.titleButtonContainer}>
          <span className={styles.title}>{title}</span>
          <Button label={buttonLabel} href={paths.SERVICES.PRODUCT_DESIGN} className={styles.button} />
        </div>
      </div>
    </NewStandardSection>
  );
};
